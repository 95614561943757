import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { verifyToken } from "src/actions/auth";
import ResetPasswordContent from "src/components/Auth/ResetPasswordContent";
import ResetPasswordUrlInvalid from "src/components/Auth/ResetPasswordUrlInvalid";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";

const VerifyToken = ({ verifyToken }) => {
  const [status, setStatus] = useState("pending");
  const params = useParams();

  const loadOut = {
    pending: <Preloader />,
    failed: <ResetPasswordUrlInvalid url="/forgot-password/submit-email" />,
    success: <ResetPasswordContent token={params.token || null} />,
  };

  useEffect(() => {
    const ac = new AbortController();

    const loadToken = async () => {
      try {
        const { message } = await verifyToken({ token: params.token || null }, ac.signal);
        setStatus("success");

        toast.success(message, {
          position: "top-right",
          autoClose: 1700,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // return navigate(`/forgot-password/reset-password/${data}`);
      } catch (error) {
        setStatus("failed");
        toast.error(error.message, {
          position: "top-right",
          autoClose: 1700,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };

    loadToken();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loadOut[status];
};

export default connect(null, { verifyToken })(VerifyToken);
