import { connect } from "react-redux";
import { useEffect, useState } from "react";
import Modal from "src/components/Shared/Modal";
import { workspaceIntegrationCredentialManageCreatedBy } from "src/actions/workspace";
import { toast } from "react-toastify";
import Select from "src/components/Shared/Forms/Selects/Select";

const WorkspacesIntegrationCreatedByManageModal = ({ isOpen, workspaceId = null, is_global = false, providerDetails = null, editItem = null, users = [], onCancel = () => {}, onSuccess = () => {}, ...props }) => {
  const [disabled, setDisabled] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ _id: -1, name: "None Selected" });

  const onSubmit = async () => {
    try {
      setDisabled(true);
      await props.workspaceIntegrationCredentialManageCreatedBy({ integration_id: providerDetails._id, workspace_id: workspaceId, credential_id: editItem?._id, created_by: selectedUser?._id });
      onSuccess();
      setDisabled(false)
    } catch (error) {
      toast.error(error.message);
      setDisabled(false)
    }
  };
  
  useEffect(() => {
    setSelectedUser(users?.find((user) => user?._id?.toString() === editItem?.created_by?.toString()) || { _id: -1, name: "None Selected" })
  }, [users, editItem?.created_by])

  return (
    <Modal
      title="Credential"
      secondaryTitle={`Manage / Created By`}
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={onSubmit}
      defaultOptions={{
        onSuccessButtonText: "Save",
        onSuccessLoaderVisible: disabled,
        onSuccessLoaderStart: disabled,
      }}>
      <div className="relative w-full space-y-4">
        <Select
          label={"Created By"}
          options={users || []}
          selected={selectedUser}
          setSelected={(e) => setSelectedUser(e)}
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, { workspaceIntegrationCredentialManageCreatedBy })(WorkspacesIntegrationCreatedByManageModal);
