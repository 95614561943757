import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import Section from "src/components/Shared/Containers/Section";
import Input from "src/components/Shared/Forms/Inputs/Input";
import { H3 } from "src/components/Shared/Text/Headers";
import useFetch from "src/hooks/useFetch";
import WorkspaceIntegrationsList from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationsList";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import { classNames } from "src/helpers/classNames";
import { CheckIcon, NoSymbolIcon } from "@heroicons/react/24/outline";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import { manageSiteSettings } from "src/actions/site";

const GlobalSettings = ({ siteData, ...props }) => {
  const {
    response: { data: site },
    refreshData: refreshSite,
  } = useFetch("/site-settings/unprotected");

  const [siteDomain, setSiteDomain] = useState("");
  const [loginPageTitle, setLoginPageTitle] = useState("");
  const [loginPageSubtitle, setLoginPageSubtitle] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [updatedChanges, setUpdateChanges] = useState(0);
  const [allowSubdomains, setAllowSubdomains] = useState(false);
  const [subDomainbuttonIsDisabled, setSubDomainButtonIsDisabled] = useState(false);

  useEffect(() => {
    setSiteDomain(site?.site_domain);
    setLoginPageTitle(site?.login_page_title);
    setLoginPageSubtitle(site?.login_page_subtitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site?._id]);

  useEffect(() => {
    setAllowSubdomains(siteData?.allow_subdomains || "");
  }, [siteData]);

  const onSubmit = async () => {
    try {
      setIsDisabled(true);
      let body = {
        site_domain: siteDomain,
        login_page_title: loginPageTitle,
        login_page_subtitle: loginPageSubtitle,
      };
      await apiRequest("put", "/site-settings", { body });
      toast.success("General settings data successfully updated.");
      setUpdateChanges(0);

      refreshSite();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSubDomainUpdate = async () => {
    try {
      setSubDomainButtonIsDisabled(true);
      const message = await props.manageSiteSettings({ allow_subdomains: allowSubdomains });
      toast.success(message);
      setSubDomainButtonIsDisabled(false);
    } catch (error) {
      setSubDomainButtonIsDisabled(false);
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-y-4">
        <Section>
          <H3 caption="Setup general website items for the best user experience.">General Settings</H3>
          <div className="flex flex-wrap w-full gap-y-6 gap-x-4">
            <div className="w-full px-2">
              <Input
                name="site-domain"
                label="User Access URL"
                value={siteDomain}
                onChange={(e) => {
                  setIsDisabled(false);
                  setUpdateChanges((cur) => cur + 1);
                  setSiteDomain(e.target.value);
                }}
              />
            </div>
            <div className="w-full px-2">
              <Input
                name="login-page-title"
                label="Login Page Title"
                value={loginPageTitle}
                onChange={(e) => {
                  setIsDisabled(false);
                  setUpdateChanges((cur) => cur + 1);
                  setLoginPageTitle(e.target.value);
                }}
              />
            </div>
            <div className="w-full px-2">
              <Input
                name="login-page-subtitle"
                label="Login Page Subtitle"
                value={loginPageSubtitle}
                onChange={(e) => {
                  setIsDisabled(false);
                  setUpdateChanges((cur) => cur + 1);
                  setLoginPageSubtitle(e.target.value);
                }}
              />
            </div>
          </div>
          {updatedChanges > 0 && (
            <div className="flex justify-end gap-x-4 items-end mt-4 px-2">
              <div className="h-10 flex gap-x-2">
                <Button
                  version="gray"
                  disabled={isDisabled}
                  onClick={() => {
                    setSiteDomain(site.site_domain);
                    setLoginPageTitle(site.login_page_title);
                    setLoginPageSubtitle(site.login_page_subtitle);
                    setIsDisabled(true);
                    setUpdateChanges(0);
                  }}>
                  Undo
                </Button>
                <Button
                  disabled={isDisabled}
                  onClick={onSubmit}>
                  Update
                </Button>
              </div>
            </div>
          )}
        </Section>
        <Section>
          <div className="w-full grid gap-y-4">
            <WorkspaceIntegrationsList
              workspaceId={null}
              workspaceDetails={null}
              is_global={true}
            />
          </div>
        </Section>
        {siteData?.digital_ocean_app_id && siteData?.digital_ocean_app_secret &&
          <Section>
          <EditContainer
            title="Allow subdomains"
            preview={
              <div className="flex items-center h-10 w-56">
                <p className={classNames("flex gap-x-2 px-2 py-1 rounded opacity-60", !allowSubdomains ? "bg-gray-50" : "bg-green-50 text-green-700")}>
                  {!allowSubdomains ? (
                    <>
                      <NoSymbolIcon className="h-5 w-5" /> Disabled
                    </>
                  ) : (
                    <>
                      <CheckIcon className="h-5 w-5" /> Enabled
                    </>
                  )}
                </p>
              </div>
            }
            onSuccess={handleSubDomainUpdate}
            defaultOptions={{
              onSuccessLoaderVisible: subDomainbuttonIsDisabled,
              onSuccessLoaderStart: subDomainbuttonIsDisabled,
            }}
            onCancel={() => setAllowSubdomains(siteData?.allow_subdomains)}>
            <div className="min-w-[260px]">
              <ToggleHeader
                title="Allow subdomains"
                subtitle="Allow workspace editors to create sub domain for each workspace. Make sure this feature has been enabled before toggling."
                position="left">
                <Toggle
                  checked={allowSubdomains}
                  onChange={() => {
                    setAllowSubdomains(!allowSubdomains);
                  }}
                />
              </ToggleHeader>
            </div>
          </EditContainer>
        </Section>
        }
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    siteData: state.site,
  };
};

export default connect(mapStateToProps, { manageSiteSettings })(GlobalSettings);
