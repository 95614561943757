import { connect } from "react-redux";
import { PencilIcon, TrashIcon, UserIcon } from "@heroicons/react/24/outline";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import DomoIntegrationClient from "./IntegrationClients/DomoIntegrationClient";
import MetabaseIntegrationClient from "./IntegrationClients/MetabaseIntegrationClient";
import Button from "src/components/Shared/Buttons/Button";
import { formatDate } from "src/helpers/formatDate";
import { classNames } from "src/helpers/classNames";
import { workspaceIntegrationCredentialMakeDefault } from "src/actions/workspace";
import { useState } from "react";
import { toast } from "react-toastify";

const WorkspaceIntegrationClientsItem = ({ workspaceId, user, integration, credential, editCredential = () => { }, openCreatedByModal = () => {}, removeCredential = () => { }, makeDefaultButtonDisabled = false, setMakeDefaultButtonDisabled = () => {}, refreshIntegrations = () => {}, ...props }) => {
  const [makeDefaultIsLoading, setMakeDefaultIsLoading] = useState(false);

  const getAttributeValue = (name) => {
    return credential.embed_attributes.find((embed_attribute) => embed_attribute.key === name)?.value;
  };

  const makeDefault = async () => {
    try {
      setMakeDefaultButtonDisabled(true)
      setMakeDefaultIsLoading(true);
      await props.workspaceIntegrationCredentialMakeDefault({ integration_id: integration._id, workspace_id: workspaceId, credential_id: credential?._id });
      setMakeDefaultIsLoading(false);
      setMakeDefaultButtonDisabled(false);
      refreshIntegrations()
    } catch (error) {
      setMakeDefaultIsLoading(false);
      setMakeDefaultButtonDisabled(false)
      toast.error(error.message);
    }
  };

  return (
    <div className="w-full h-12 flex border border-gray-50 shadow py-1 px-4 rounded-md overflow-hidden">
      <div className="flex w-2/3">
        {integration?.code === "domo" && (
          <DomoIntegrationClient
            credential={credential}
            getAttributeValue={getAttributeValue}
          />
        )}
        {integration?.code === "metabase" && (
          <MetabaseIntegrationClient
            credential={credential}
            getAttributeValue={getAttributeValue}
          />
        )}
      </div>

      <div className="w-1/3 flex justify-between items-center">
        <div className="flex flex-col items-center justify-center">
          {credential?.user?.name && <div className="text-sm text-gray-800 font-semibold">{credential?.user?.name}</div>}
          <div className="text-xs text-gray-400 flex items-center">Created {formatDate(credential?.created_at, "MM/DD/YYYY")}</div>
        </div>
        <div className="flex gap-x-1">
          {authorizeUserComponentAccess(user, workspaceId, "integration", ["update"]) && (
            <>
              {workspaceId && credential?.created_by?.toString() === user?._id?.toString() &&
                <Button
                  disabled={makeDefaultButtonDisabled || credential?.default_users_credential?.includes(user?._id)}
                  loading={makeDefaultIsLoading}
                  onClick={makeDefault}
                  version={credential?.default_users_credential?.includes(user?._id) ? "default" : "gray"}
                  className={classNames("flex items-center justify-center rounded-md px-2 text-xs font-semibold leading-5", credential?.default_users_credential?.includes(user?._id) ? "bg-green-200 text-green-700 opacity-50" : "bg-gray-50 text-gray-500")}>
                  {credential?.default_users_credential?.includes(user?._id) ? "Default" : "Make default"}
                </Button>
              }
              {workspaceId && user?.type === "admin" &&
                <Button
                  type="button"
                  version="default"
                  onClick={() =>
                    openCreatedByModal(
                      integration,
                      credential
                    )
                  }
                  className="w-8 h-8 flex items-center justify-center border-gray-200 rounded-md text-gray-500 hover:text-gray-600 outline-none ring-0 transition-all duration-200 hover:bg-gray-50">
                  <UserIcon className="w-5 h-5" />
                </Button>
              }
              <Button
                type="button"
                version="default"
                onClick={() =>
                  editCredential(
                    integration,
                    integration?.embed_types.find((embed_type) => embed_type.type === credential?.integration_type),
                    credential
                  )
                }
                className="w-8 h-8 flex items-center justify-center border-gray-200 rounded-md text-gray-500 hover:text-gray-600 outline-none ring-0 transition-all duration-200 hover:bg-gray-50">
                <PencilIcon className="w-5 h-5" />
              </Button>
              <Button
                type="button"
                version="default"
                onClick={() => removeCredential(integration._id, credential?._id)}
                className="w-8 h-8 flex items-center justify-center border-gray-200 rounded-md text-gray-500 hover:text-gray-600 outline-none ring-0 transition-all duration-200 hover:bg-gray-50">
                <TrashIcon className="w-5 h-5" />
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, { workspaceIntegrationCredentialMakeDefault })(WorkspaceIntegrationClientsItem);